import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from "react-bootstrap"
import Header from "../components/header"
import Seo from "../components/seo"
import bubblestwo from "../images/speechbubbles2.png"
import bubblesthree from "../images/speechbubbles3.png"

const ComingsoonPage = () => (
  <>
  <div>
    <Seo title="Home" />
    <Header />
    <Row style={{margin: `0`, padding:'5px 1em', backgroundColor: '#98c5ef'}}>
      <Col xs={6}>
        <p style={{ textAlign:'left', margin: '0', color:`#01264f`, fontFamily:`Avenir Next Condensed`, fontWeight:`500`, fontSize:`14px`}}>English Accent Training | Speech+Language Therapy | Reading+Literacy Intervention</p>
      </Col> 
      <Col xs={6} style={{textAlign:'right'}}>
        <a href="tel:+2252786015" style={{color:`#01264f`, fontFamily:`Rockwell`, fontWeight:`800`, fontSize:`18px`, textDecoration:'none'}}>225.278.6015</a>
      </Col>
   </Row>
    <Container fluid>
      <Row style={{marginTop: `20px`}}>
        <Col sm={3} style={{margin: `0`, padding: `0`}}>  
        <img src={bubblestwo} alt="Singh Speech, Language and Reading Intervention" style={{height:'auto',width:'100%'}} />  
       </Col>
        <Col sm={6}> 
        <p style={{textAlign:'center', fontFamily:'Avenir Next Condensed', fontSize:'36px', fontStyle:'900', margin:'1.5em 0'}}>This page is in progress.  Please check again soon!</p>
        <p style={{textAlign:'center', fontFamily:'Avenir Next Condensed', fontSize:'36px', fontStyle:'900', margin:'1.5em 0'}}>For more information, email hello@singhspeech.com.</p>
        </Col>
        <Col sm={3}>
        <img src={bubblesthree} alt="Singh Speech, Language and Reading Intervention" style={{height:'auto',width:'100%', marginTop:'15px'}} />  
        </Col>
      </Row>
     
      <Row>
      <Col>  <p style={{textAlign:'center', fontFamily:'Avenir Next Condensed', fontSize:'36px', margin:'1.25em 4em'}}>Schedule a free 15-minute consult:</p>

<div style={{textAlign:'center', width:'200px',border:'2px solid #26bd0f',fontFamily:'Avenir Next Condensed', fontSize:'24px', padding:'1em', margin:'0 auto'}}>
  <a href="https://leah-singh.clientsecure.me" data-spwidget-scope-id="9499cadb-6b09-4b98-aca6-1512b5f8b55d" data-spwidget-scope-uri="leah-singh" data-spwidget-application-id="7c72cb9f9a9b913654bb89d6c7b4e71a77911b30192051da35384b4d0c6d505b" data-spwidget-scope-global data-spwidget-autobind>Request Consult</a>
</div>
        </Col>
      </Row>
    </Container> 
    <footer
      style={{
      marginTop: `2rem`,
      textAlign: `center`
    }}
  >
  © {new Date().getFullYear()}
  {` `}
  <a href="https://www.lsdigitaltech.com">LS Digital Tech, LLC.</a>
</footer>
</div>
</>
)

export default ComingsoonPage
